<template>
    <div class="app-date-range" :class="{ 'hide-calendar-icon': are_values_selected }">
        <date-range-picker
            :date-range="value"
            :auto-apply="autoApply"
            :opens="opens"
            :locale-data="localeData"
            :single-date-picker="singleDatePicker"

            @update="onUpdate"
        >
            <template v-slot:input="picker">
                <span v-if="picker.startDate">
                    {{ picker.startDate | date(localeData) }} {{ divider }} {{ picker.endDate | date(localeData) }}
                </span>
                <span v-else>
                    Custom range
                </span>
            </template>
        </date-range-picker>

        <button class="btn btn-table-action action-remove action-secondary btn-clear" v-if="are_values_selected" @click="clear"></button>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import formatDate from '@/helpers/format-date'

export default {
    props: {
        value:            { type: Object,            required: true    },
        autoApply:        { type: Boolean,           default: true     },
        opens:            { type: String,            default: 'right'  },
        localeData:       { type: Object,            default: () => {} },
        singleDatePicker: { type: [Boolean, String], default: false    },
        divider:          { type: String,            default: '-'      },
    },

    components: {
        DateRangePicker,
    },

    methods: {
        onUpdate(value) {
            this.$emit('input', value)
            this.$emit('change', value)
        },

        clear() {
            const value = {
                startDate: null,
                endDate: null,
            }

            this.onUpdate(value)
        },
    },

    computed: {
        are_values_selected() {
            return Boolean(this.value.startDate && this.value.endDate)
        },
    },

    filters: {
        date(val, localeData) {
            const format = localeData.format

            return formatDate(val, format)
        },
    },
}
</script>

<style lang="scss">
.app-date-range {
    position: relative;

    width: 100%;
    min-width: 0;

    .vue-daterange-picker {
        display: block;

        min-width: 100%;

        .reportrange-text {
            position: relative;

            padding: 11px #{ 8px + 24px + 8px } 11px 14px;

            font-size: 16px;
            font-weight: normal;

            color: var(--color-text-primary);

            background: var(--color-input-bg);
            border-radius: $border-radius-primary;
            border: solid 1px var(--color-input-border);

            span {
                display: block;

                @include text-overflow();
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 8px;

                display: block;
                width: 24px;
                height: 24px;

                color: var(--color-icon-calm);

                transform: translateY(-50%);

                @include icon($icon-calendar);
            }
        }

        .daterangepicker {
            margin-top: 8px;
            padding: 24px;

            font-size: 16px;
            line-height: 24px;

            background-color: var(--color-component-bg-primary);
            border: none;
            border-radius: $border-radius-primary;
            box-shadow: var(--box-shadow-secondary);

            .calendars {
                display: flex;
                flex-wrap: nowrap;

                .ranges {
                    flex: none;

                    width: auto;
                    max-width: none;

                    padding: 0;

                    border-radius: $border-radius-primary;
                    border: solid 1px var(--color-input-border);

                    ul {
                        padding: 12px;

                        li {
                            width: fit-content;

                            margin-bottom: 12px;
                            padding: 0;

                            font-size: 16px;

                            transition: $transition-duration-primary;

                            &.active {
                                color: var(--color-text-default);
                                background-color: transparent;
                            }

                            &:hover {
                                color: var(--color-link-hover);
                                background-color: transparent;
                            }

                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }

                .calendars-container {
                    .drp-calendar {
                        /* @todo */
                        max-width: none;
                        width: auto;

                        .calendar-table {
                            border: none;
                            border-radius: 0;
                            background-color: transparent;

                            .table-condensed {
                                th,
                                td {
                                    width: 40px;
                                    min-width: 40px;
                                    height: 40px;

                                    padding: 4px;

                                    font-size: 16px;
                                    line-height: 24px;

                                    // background-color: transparent;
                                    border: none;
                                    // border-radius: 0;

                                    &:focus {
                                        outline: none;
                                    }
                                }

                                thead {
                                    tr {
                                        th {
                                            // &.prev {}

                                            // &.next {}

                                            &.month {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        th {
                                            color: var(--color-table-secondary);
                                        }

                                        td {
                                            &.in-range {
                                                color: var(--color-text-default);
                                                background-color: var(--color-table-row-bg-hover);
                                            }

                                            &.start-date {
                                                border-radius: $border-radius-primary 0 0 $border-radius-primary;
                                            }

                                            &.end-date {
                                                border-radius: 0 $border-radius-primary $border-radius-primary 0;
                                            }

                                            &.start-date.end-date {
                                                border-radius: $border-radius-primary;
                                            }

                                            &.off {
                                                color: var(--color-table-secondary);
                                                background-color: transparent;

                                                &.end-date,
                                                &.start-date,
                                                &.in-range {
                                                    color: var(--color-table-secondary);
                                                    background-color: transparent;
                                                }
                                            }

                                            &.active {
                                                color: var(--color-btn-primary);
                                                background-color: var(--color-btn-primary-bg);

                                                &:hover {
                                                    color: var(--color-btn-primary);
                                                    background-color: var(--color-btn-primary-bg);
                                                }
                                            }

                                            &.today {
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.left {
                            padding: 0 20px;
                        }

                        &.right {
                            padding: 0 0 0 20px;

                            border-left: 1px solid var(--color-divider);
                        }
                    }
                }
            }

            &.show-calendar {
                .ranges {
                    margin-top: 0;
                }
            }

            &.show-ranges {
                .drp-calendar {
                    &.left {
                        border-left: none;
                    }
                }
            }

            &.opensright {
                left: 0;
            }

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .btn-clear {
        position: absolute;
        top: 50%;
        right: 8px;

        transform: translateY(-50%);
    }

    &.hide-calendar-icon {
        .vue-daterange-picker {
            .reportrange-text {
                &::after {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 564px) {
    .app-date-range {
        .vue-daterange-picker {
            .daterangepicker {
                .calendars {
                    .ranges {
                        ul {
                            width: 184px;
                        }
                    }
                }

                &.ltr {
                    .drp-calendar {
                        &.left {
                            .calendar-table {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>